// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-data-data-definitions-js": () => import("./../../../src/pages/about-data/data-definitions.js" /* webpackChunkName: "component---src-pages-about-data-data-definitions-js" */),
  "component---src-pages-about-data-data-summary-js": () => import("./../../../src/pages/about-data/data-summary.js" /* webpackChunkName: "component---src-pages-about-data-data-summary-js" */),
  "component---src-pages-about-data-federal-resources-js": () => import("./../../../src/pages/about-data/federal-resources.js" /* webpackChunkName: "component---src-pages-about-data-federal-resources-js" */),
  "component---src-pages-about-data-state-reporting-assessments-js": () => import("./../../../src/pages/about-data/state-reporting-assessments.js" /* webpackChunkName: "component---src-pages-about-data-state-reporting-assessments-js" */),
  "component---src-pages-about-data-total-tests-js": () => import("./../../../src/pages/about-data/total-tests.js" /* webpackChunkName: "component---src-pages-about-data-total-tests-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-sitemap-js": () => import("./../../../src/pages/about/sitemap.js" /* webpackChunkName: "component---src-pages-about-sitemap-js" */),
  "component---src-pages-about-track-impact-index-js": () => import("./../../../src/pages/about/track-impact/index.js" /* webpackChunkName: "component---src-pages-about-track-impact-index-js" */),
  "component---src-pages-about-track-impact-success-js": () => import("./../../../src/pages/about/track-impact/success.js" /* webpackChunkName: "component---src-pages-about-track-impact-success-js" */),
  "component---src-pages-analysis-updates-test-positivity-js": () => import("./../../../src/pages/analysis-updates/test-positivity.js" /* webpackChunkName: "component---src-pages-analysis-updates-test-positivity-js" */),
  "component---src-pages-data-api-index-js": () => import("./../../../src/pages/data/api/index.js" /* webpackChunkName: "component---src-pages-data-api-index-js" */),
  "component---src-pages-data-api-version-2-js": () => import("./../../../src/pages/data/api/version-2.js" /* webpackChunkName: "component---src-pages-data-api-version-2-js" */),
  "component---src-pages-data-charts-js": () => import("./../../../src/pages/data/charts.js" /* webpackChunkName: "component---src-pages-data-charts-js" */),
  "component---src-pages-data-download-js": () => import("./../../../src/pages/data/download.js" /* webpackChunkName: "component---src-pages-data-download-js" */),
  "component---src-pages-data-hospital-facilities-index-js": () => import("./../../../src/pages/data/hospital-facilities/index.js" /* webpackChunkName: "component---src-pages-data-hospital-facilities-index-js" */),
  "component---src-pages-data-index-js": () => import("./../../../src/pages/data/index.js" /* webpackChunkName: "component---src-pages-data-index-js" */),
  "component---src-pages-data-national-cases-js": () => import("./../../../src/pages/data/national/cases.js" /* webpackChunkName: "component---src-pages-data-national-cases-js" */),
  "component---src-pages-data-national-chart-tables-js": () => import("./../../../src/pages/data/national/chart-tables.js" /* webpackChunkName: "component---src-pages-data-national-chart-tables-js" */),
  "component---src-pages-data-national-deaths-js": () => import("./../../../src/pages/data/national/deaths.js" /* webpackChunkName: "component---src-pages-data-national-deaths-js" */),
  "component---src-pages-data-national-hospitalization-js": () => import("./../../../src/pages/data/national/hospitalization.js" /* webpackChunkName: "component---src-pages-data-national-hospitalization-js" */),
  "component---src-pages-data-national-index-js": () => import("./../../../src/pages/data/national/index.js" /* webpackChunkName: "component---src-pages-data-national-index-js" */),
  "component---src-pages-data-national-tests-js": () => import("./../../../src/pages/data/national/tests.js" /* webpackChunkName: "component---src-pages-data-national-tests-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-build-js": () => import("./../../../src/pages/latest-build.js" /* webpackChunkName: "component---src-pages-latest-build-js" */),
  "component---src-pages-nursing-homes-long-term-care-facilities-about-data-js": () => import("./../../../src/pages/nursing-homes-long-term-care-facilities/about-data.js" /* webpackChunkName: "component---src-pages-nursing-homes-long-term-care-facilities-about-data-js" */),
  "component---src-pages-nursing-homes-long-term-care-facilities-data-by-state-js": () => import("./../../../src/pages/nursing-homes-long-term-care-facilities/data-by-state.js" /* webpackChunkName: "component---src-pages-nursing-homes-long-term-care-facilities-data-by-state-js" */),
  "component---src-pages-nursing-homes-long-term-care-facilities-history-js": () => import("./../../../src/pages/nursing-homes-long-term-care-facilities/history.js" /* webpackChunkName: "component---src-pages-nursing-homes-long-term-care-facilities-history-js" */),
  "component---src-pages-nursing-homes-long-term-care-facilities-index-js": () => import("./../../../src/pages/nursing-homes-long-term-care-facilities/index.js" /* webpackChunkName: "component---src-pages-nursing-homes-long-term-care-facilities-index-js" */),
  "component---src-pages-nursing-homes-long-term-care-facilities-map-js": () => import("./../../../src/pages/nursing-homes-long-term-care-facilities/map.js" /* webpackChunkName: "component---src-pages-nursing-homes-long-term-care-facilities-map-js" */),
  "component---src-pages-race-dashboard-js": () => import("./../../../src/pages/race/dashboard.js" /* webpackChunkName: "component---src-pages-race-dashboard-js" */),
  "component---src-pages-race-index-js": () => import("./../../../src/pages/race/index.js" /* webpackChunkName: "component---src-pages-race-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-chart-js": () => import("./../../../src/templates/chart.js" /* webpackChunkName: "component---src-templates-chart-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-document-js": () => import("./../../../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */),
  "component---src-templates-state-assessment-js": () => import("./../../../src/templates/state/assessment.js" /* webpackChunkName: "component---src-templates-state-assessment-js" */),
  "component---src-templates-state-cases-js": () => import("./../../../src/templates/state/cases.js" /* webpackChunkName: "component---src-templates-state-cases-js" */),
  "component---src-templates-state-chart-tables-js": () => import("./../../../src/templates/state/chart-tables.js" /* webpackChunkName: "component---src-templates-state-chart-tables-js" */),
  "component---src-templates-state-hospitalization-js": () => import("./../../../src/templates/state/hospitalization.js" /* webpackChunkName: "component---src-templates-state-hospitalization-js" */),
  "component---src-templates-state-index-js": () => import("./../../../src/templates/state/index.js" /* webpackChunkName: "component---src-templates-state-index-js" */),
  "component---src-templates-state-long-term-care-history-js": () => import("./../../../src/templates/state/long-term-care/history.js" /* webpackChunkName: "component---src-templates-state-long-term-care-history-js" */),
  "component---src-templates-state-long-term-care-index-js": () => import("./../../../src/templates/state/long-term-care/index.js" /* webpackChunkName: "component---src-templates-state-long-term-care-index-js" */),
  "component---src-templates-state-notes-js": () => import("./../../../src/templates/state/notes.js" /* webpackChunkName: "component---src-templates-state-notes-js" */),
  "component---src-templates-state-outcomes-js": () => import("./../../../src/templates/state/outcomes.js" /* webpackChunkName: "component---src-templates-state-outcomes-js" */),
  "component---src-templates-state-race-ethnicity-historical-js": () => import("./../../../src/templates/state/race-ethnicity/historical.js" /* webpackChunkName: "component---src-templates-state-race-ethnicity-historical-js" */),
  "component---src-templates-state-race-ethnicity-index-js": () => import("./../../../src/templates/state/race-ethnicity/index.js" /* webpackChunkName: "component---src-templates-state-race-ethnicity-index-js" */),
  "component---src-templates-state-tests-antibody-js": () => import("./../../../src/templates/state/tests-antibody.js" /* webpackChunkName: "component---src-templates-state-tests-antibody-js" */),
  "component---src-templates-state-tests-antigen-js": () => import("./../../../src/templates/state/tests-antigen.js" /* webpackChunkName: "component---src-templates-state-tests-antigen-js" */),
  "component---src-templates-state-tests-viral-js": () => import("./../../../src/templates/state/tests-viral.js" /* webpackChunkName: "component---src-templates-state-tests-viral-js" */)
}

